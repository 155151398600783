import Home from '@/components/screens/home/Home';
import { NextPage, GetStaticProps } from 'next';
import { API_URL, POPULAR_BOOKS, REVALIDATE_TIME } from '@/helpers/variables';
import { IBookPage, IBooksPageData } from '@/interfaces/booksPage.interface';
import { IAdvertisingBlock } from '@/interfaces/advertisingBlock';

interface IHomePage {
  popularBooks: IBooksPageData[];
  newBooks: IBookPage;
  advertisingBlock: IAdvertisingBlock;
}

const HomePage: NextPage<IHomePage> = ({ popularBooks, newBooks, advertisingBlock }) => {
  return <Home popularBooks={popularBooks} newBooks={newBooks} advertisingBlock={advertisingBlock} />;
};

export const getStaticProps: GetStaticProps = async () => {
  const [popularRes, newRes, advertisingBlockRes] = await Promise.all([
    fetch(`${API_URL}satellite/books/popularity?page=1&page_size=${POPULAR_BOOKS}`),
    fetch(`${API_URL}satellite/books/news?page=1&page_size=6`),
    fetch(`${API_URL}advertising_block`),
  ]);
  const [popularBooks, newBooks, advertisingBlock] = await Promise.all([popularRes.json(), newRes.json(), advertisingBlockRes.json()]);

  return { props: { popularBooks, newBooks, advertisingBlock }, revalidate: REVALIDATE_TIME };
};

export default HomePage;
