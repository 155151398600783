import { FC } from 'react';
import Layout from '@/components/layout/Layout';
import SectionTitle from '@/components/ui/section-title/SectionTitle';
import BookCardItem from '@/components/ui/book-card-item/BookCardItem';
import SlickSlider from '@/components/ui/slick-slider/SlickSlider';
import SlickSliderPartner from '@/components/ui/slick-slider/SlickSliderPartner';
import { IBookPage, IBooksPageData } from '@/interfaces/booksPage.interface';
import { IAdvertisingBlock } from '@/interfaces/advertisingBlock';

interface IHomePage {
  popularBooks: IBooksPageData[];
  newBooks: IBookPage;
  advertisingBlock: IAdvertisingBlock;
}

const Home: FC<IHomePage> = ({ popularBooks, newBooks, advertisingBlock }) => {
  //seo block
  const SEO_TITLE = 'Куча книг - электронная библиотека. Скачать книгу, читать книги онлайн, электронные книги бесплатно.';
  const SEO_DESCRIPTION = 'Электронная библиотека - Куча книг. У нас можно читать книги, скачать книгу online бесплатно. Бесплатно - Электронная библиотека и электронные книги.';

  return (
    <Layout title={SEO_TITLE} description={SEO_DESCRIPTION} canonicalHref="/" isDefaultPopularSlider={false}>
      <SectionTitle name={'Новинки'} />
      <SlickSlider newBooks={newBooks.data} />
      <SectionTitle name={'Популярные книги'} extraClassName={'sectionHeading_popular'} />
      <div>
        {popularBooks.length ? (
          popularBooks.map((book) => <BookCardItem createdAt={book.created_at} alias={book.alias} rating={book.rating} key={book.id} author={book.author} book={book.book} draft={book.draft} readOnline={book.read_online} />)
        ) : (
          <div>Книги не найдены</div>
        )}
      </div>
      <SectionTitle name={'Лучшие книги нашего партнера'} />
      <SlickSliderPartner books={advertisingBlock} />
    </Layout>
  );
};

export default Home;
